export default {
	historyList: [
		{
			year: "2018",
			list: [{
				text: "入驻厦门万科云熙五星级海景写字楼，品牌全新升级为“黑谷科技”",
			},
			{
				text: "黑谷新产品“共享商圈”上线",
			},
			{
				text: "黑谷新产品“好口碑”上线",
			},
			{
				text: "黑谷全智能新品“AI门卫”上线",
			},
			],
		},
		{
			year: "2019",
			list: [{
				text: "推出“共享演唱会”，在全国进行了4场巡演，全国超10万人现场观看，邀请了邓紫棋、张信哲、梁静茹、田馥甄、刘若英、张韶涵、吴克群、杨宗纬等华语巨星",
			}, {
				text: '黑谷新项目“共享派对”上线'
			}],
		},
		{
			year: "2020",
			list: [{
				text: '黑谷科技成为"第33届中国电影金鸡奖"影迷嘉年华指定合作伙伴',
			},
			{
				text: "公司办公规模扩展至上万平方，员工1000+；正式成立抖音团队，团队规模达上百人",
			},
			],
		},
		{
			year: "2021",
			list: [{
				text: "黑谷跨界抖音，正式发布“抖音达人”项目，助力实体门店",
			}],
		},
		{
			year: "2022",
			list: [{
				text: "黑谷1号产品上线",
			}, {
				text: "客源引爆系统上线（交个朋友）",
			}],
		},
		{
			year: "2023",
			list: [{
				text: "推出黑谷AI系列产品，涵盖了AI超级销售、AI获客、AI会议秘书、AI人才猎手、AI财务、AI设计等",
			}],
		},
	],
	honorList: [
		{
			text: "入选省级重点上市后备企业，荣获企业上市扶持奖励50万",
			word: "ONE",
		},
		{
			text: "荣获国家级高新技术企业认定，获得政府30万资金奖励",
			word: "TWO",
		},
		{
			text: "厦门市湖里区新一代信息产业发展优秀企业，获得政府专项资金40万",
			word: "THREE",
		},
		{
			text: "黑谷科技成为字节跳动旗下厦门市抖音服务商",
			word: "FOUR",
		},
		{
			text: "巨量引擎厦门直营中心客户委员会委员",
			word: "FIVE",
		},
		{
			text: "第33届中国电影金鸡奖颁奖盛典系列活动影迷嘉年华“指定合作伙伴”",
			word: "SIX",
		},
		{
			text: "第七届金骏奖最具潜力品牌企业",
			word: "SEVEN",
		},
		{
			text: "黑谷科技CEO李全发任职汽车服务企业评价与自律委员会委员",
			word: "EIGHT",
		},
		{
			text: "黑谷科技荣获爱心企业荣誉称号",
			word: "NINE",
		},
		{
			text: "获中国最佳商业模式创新奖",
			word: "TEN",
		},
		{
			text: "获中国经济新模式十大创新企业奖",
			word: "ELEVEN",
		},
		{
			text: "CIAAF|ACTF郑州展行业最具人气品牌奖",
			word: "TWELVE",
		},
		{
			text: "获金俊奖最具潜力品牌企业奖",
			word: "THIRTEEN",
		},
		{
			text: "入选CIAACE组委会优选项目",
			word: "FOURTEEN",
		},
		{
			text: "担任广东汽车文化俱乐部常务理事单位",
			word: "FIFTEEN",
		},
		{
			text: "获中国经济新模式十大创新人物奖",
			word: "SIXTEEN",
		},
		{
			text: "中国著名财经作家吴晓波亲临黑谷",
			word: "SEVENTEEN",
		},
		{
			text: "荣获厦门市汽车配件用品行业协会“杰出贡献企业家”",
			word: "EIGHTEEN",
		},
		{
			text: "获中国品牌创新发展论坛“中国品牌榜·诚信品牌奖”",
			word: "NINETEEN",
		},
		{
			text: "获中国品牌创新发展论坛“中国品牌榜·领军人物奖”",
			word: "TWENTY",
		},
		{
			text: "黑谷荣获“中国3.15诚信企业” “中国3.15诚信品牌” “中国3.15可信赖产品”三项殊荣",
			word: "TWENTY-ONE",
		},
		{
			text: "黑谷科技入选【2022年全省数字经济领域创新企业】",
			word: "TWENTY-TWO",
		},
		{
			text: "黑谷科技荣获中国民营企业100强",
			word: "TWENTY-THREE",
		},
		{
			text: "黑谷科技荣获中国软件和信息技术行业最具影响力十大领先企业",
			word: "TWENTY-FOUR",
		},
		{
			text: "黑谷科技荣获全国共享店铺行业市场用户首选品牌",
			word: "TWENTY-FIVE",
		},
		{
			text: "黑谷科技入选【厦门市重点软件和信息技术服务企业】",
			word: "TWENTY-SIX",
		},
		{
			text: "黑谷科技获得【福建省数字经济核心产业领域创新企业、市重点软件企业】称号",
			word: "TWENTY-SEVEN",
		},
		{
			text: "黑谷科技凭荣膺【厦门市2022年度重点产业龙头骨干民营企业】",
			word: "TWENTY-EIGHT",
		},
		{
			text: "黑谷科技当选【中国消费者可信赖企业】",
			word: "TWENTY-NINE",
		},
		{
			text: "黑谷科技获得【中国自主创新最具影响力品牌】荣誉称号",
			word: "THIRTY",
		},
		{
			text: "黑谷科技荣登2022年度福建省重点上市后备企业名单",
			word: "THIRTY-ONE",
		},
		{
			text: "黑谷科技连续两年入选厦门市重点软件和信息技术服务企业",
			word: "THIRTY-TWO",
		},
		{
			text: "黑谷科技入围【2023年百金计划】",
			word: "THIRTY-THREE",
		},
		{
			text: "黑谷科技创始人李总荣获【厦门市青年创业人才】称号",
			word: "THIRTY-FOUR",
		},
		{
			text: "黑谷科技荣获湖里区2023年创新型中小企业称号",
			word: "THIRTY-FIVE",
		},
	],
}

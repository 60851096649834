














































import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";
import vSelect from './v-select.vue'
import { getSmsCode, signUp } from "../api"

@Component({
  components: { vSelect }
})
export default class extends Vue {
  private showMsg = false
		private msg = ''
  private groupName = ''
  private industryCode = ''
  private noteCode = ''
  private timer = 0
  private mobile = ''
  private options = [
    {
        value: 0,
        name: "汽车"
      },
      {
        value: 1,
        name: "美业"
      },
      {
        value: 2,
        name: "餐饮"
      },
      {
        value: 4,
        name: "教育"
      },
      // {
      //   value: 99,
      //   name: "其他"
      // }
  ]

  @Prop({
    default: false
  }) visible !: boolean

  @Prop() phone !: string

  @Watch('phone') watchPhone(phone:string){
    this.mobile = phone
  }
  
  @Emit('onClose') onClose(){
    return false
  }
  
  select(value:string){
    this.industryCode = value
  }

  interval(){
    let interval = setTimeout(()=>{
      this.timer = this.timer - 1
      if(this.timer>0){
        this.interval()
      } else {
        clearTimeout(interval)
      }
    }, 1000)
  }
showToast(msg: any) {
			this.showMsg = true;
			this.msg = msg;
			setTimeout(() => {
				this.showMsg = false;
			}, 2000)
		}
  async getSmsCode(){
    if(this.timer==0){
      const result = await getSmsCode(this.mobile)
      if(result){
        this.timer = 30
        this.interval()
        this.showToast('发送成功！')
      }
    }
  }

  async submit(){
    let openId= this.$route.query.openId || this.$route.query.openid;
			let sourceCode=openId? 79:122;
    const result = await signUp({
      groupName: this.groupName,
      industryCode: this.industryCode,
      mobile: this.mobile,
      noteCode: this.noteCode,
      sourceCode: sourceCode,
      openId: openId
    }).catch((result)=>{
				this.showToast(result.message);
			})
    if(result){
      this.showToast('报名成功！')
     setTimeout(()=>{
        this.onClose()
     },800)
    }
  }
}

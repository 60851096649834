














































import {
	Component,
	Prop,
	Vue
} from "vue-property-decorator";
import cultureData from '@/assets/data/pages2/newCulture'
import vFreeApply from '@/components/v-free-apply.vue';

@Component({
	components: {
		vFreeApply
	}
})
export default class extends Vue {
	private historyList = cultureData.historyList
	private honorList = cultureData.honorList
}















import { Component, Vue } from "vue-property-decorator";
import vApply from './v-apply.vue'

@Component({
  components: { vApply }
})
export default class extends Vue {
  private phone = ''
  private visible = false
  private Message: any
  apply(){
    if(!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.phone)){
      this.Message('error','请填写正确的手机号')
    }else{
      this.visible = true
    }
  }
}
